import * as React from "react"
import { graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLink } from "@fortawesome/free-solid-svg-icons"
import ReactEmbedGist from "react-embed-gist"
import Layout from "../components/layout"
import Seo from "../components/seo"

const isBrowser = typeof window !== "undefined"

const Project = ({ project }) => {
  const { title, description, image, links, technologies } = project.node

  return (
    <div className="rounded overflow-hidden shadow-lg bg-white mb-3 flex flex-col">
      <div>
        {image && (
          <div className="image-container">
            <img className="w-full" src={image.url} alt={image.alt} loading="lazy" />
          </div>
        )}
      </div>

      <div>
        <div className="px-6 py-4 pb-0">
          <div className="font-bold text-xl mb-2">{title}</div>
          <p className="text-slate-700 text-base">{description}</p>
        </div>
        {links && (
          <div className="px-6 py-4 pb-0 text-xs">
            <p>
              {links.map(link => (
                <a key={link.title} className="mr-3" href={link.link} target="_blank" rel="noreferrer">
                  {link.title} <FontAwesomeIcon icon={faLink} />
                </a>
              ))}
            </p>
          </div>
        )}
        <div className="px-6 py-4">
          {technologies &&
            technologies.map(technology => (
              <span key={technology} className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                {technology}
              </span>
            ))}
        </div>
      </div>
    </div>
  )
}

const Projects = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const projects  = data.allProjectsJson.edges
  const isOdd     = projects.length % 2 !== 0

  if (!isBrowser) {
    return null;
  }

  return (
    <Layout location={location} title={siteTitle}>
      <header>
        <div id="technologies">
          <h2 className="font-semibold text-2xl">
            <a className="text-slate-500 italic" href="#technologies">
              #.
            </a>{" "}
            Technologies
          </h2>
          <p>
            I mainly focus on software development for <strong>web</strong> and{" "}
            <strong>mobile</strong>. For the past years, I've been actively
            working with {" "}
            <a href="https://reactjs.org/">React</a>,{" "}
            <a href="https://facebook.github.io/react-native/">React Native</a>,{" "}
            <a href="https://vuejs.org/">Vue</a>,{" "}
            <a href="https://laravel.com/">Laravel</a> (PHP) and {" "}
            <a href="https://www.ruby-lang.org/en/">Ruby</a>{" "}
            <span className="text-sm">
              (mainly <a href="https://rubyonrails.org/">Rails</a>)
            </span>
            .
          </p>

          <p className="text-sm">
            I have been working in Software Engineering since 2008. I have
            worked in Back End technologies, Front End and mobile development
            during this time, with more inclination towards Front End oriented
            work.
          </p>

          <div className="rounded shadow-lg bg-white p-3 text-xs">
            <div className="grid sm:grid-cols-1 md:grid-cols-3 gap-2">
              <div>
                <ul className="ml-4 mb-0 list-disc">
                  <li>
                    <strong>JavaScript / TypeScript:</strong> Vue, Svelte,
                    React, React Native.
                    <ul className="ml-6 mb-0 list-disc">
                      <li>
                        <strong>State Management:</strong> Redux,
                        XState, Context.
                      </li>
                      <li>
                        <strong>Testing:</strong> Jest, Enzyme,
                        Mocha, React Testing Library.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <strong>Ruby:</strong> Rails, Sinatra, Capistrano, Jekyll.
                  </li>
                  <li>
                    <strong>Python:</strong> Django, Flask.
                  </li>
                  <li>
                    <strong>ActionScript:</strong> Flex.
                  </li>
                  <li>
                    <strong>Database Engines:</strong> SQL Server, MongoDB,
                    MySQL/MariaDB, PostgreSQL, Redis.
                  </li>
                  <li>
                    <strong>Other testing Frameworks:</strong> PHPUnit, JUnit,
                    Test::Unit, Faker, Capybara, Selenium.
                  </li>
                  <li>
                    <strong>Cloud infrastructures:</strong> Azure, Amazon Web
                    Services.
                  </li>
                  <li>
                    <strong>Hadoop Ecosystem:</strong> MapReduce, Sqoop, Impala,
                    Parquet, Hive.
                  </li>
                </ul>
              </div>

              <div className="col-span-2 max-w-full overflow-auto">
                <ReactEmbedGist gist="demogar/d5562f94d8dcd834f0fbf912c2555cdc" />
              </div>
            </div>
          </div>
        </div>
      </header>

      <header className="mt-6" id="background">
        <h2 className="font-semibold text-lg">
          <a className="text-slate-500 italic" href="#background">
            #.
          </a>{" "}
          Background
        </h2>

        <p>
          I'm not only a software developer. I have prior experience in Project
          Managament (on both, traditional / waterfall and Agile). I'm a
          certified Scrum Master and I also have a Master's Degree in Project
          Management.
        </p>

        <div className="rounded shadow-lg bg-white p-3 text-xs">
          <p className="m-0">
            <strong>Certifications I currently hold:</strong> Professional Scrum
            Master &#8482; (
            <a href="https://www.scrum.org/user/521321">PSM I</a>), Scrum Master
            Certified (SMC &reg;), Cobit 5, ITIL v3, IPMA-D.
          </p>
        </div>
      </header>

      <header className="mt-6" id="projects">
        <h2 className="font-semibold text-lg">
          <a className="text-slate-500 italic" href="#projects">
            #.
          </a>{" "}
          Projects
        </h2>
        <p>
          I've had the opportunity to work or lead several project during the
          past years. Here is a showcase of some of those:
        </p>
      </header>

      <div
        id="projects-list"
        className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-4"
      >
        {projects.map((project, index) => {
          const classes = isOdd && index === 0 ? "col-span-2" : "";

          return (
            <div className={classes}>
              <Project key={index} project={project} />
            </div>
          )
        })}
      </div>
    </Layout>
  )
}

export default Projects

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Projects" />

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    },
    allProjectsJson {
      edges {
        node {
          title
          description
          image {
            url
            alt
          }
          links {
            title
            link
          }
          technologies
        }
      }
    }
  }
`
